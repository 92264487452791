.input-group {

  &:focus-within > .input-group-append .input-group-text img,
  &:focus-within > .input-group-prepend .input-group-text img {
    opacity: 1;
  }

  .input-group-text {
    background-color: #FFFFFF;
    border: 1px solid #E7ECF5;

    img {
      opacity: 0.5;
    }
  }

  .form-control {
    background-color: #FFFFFF;
    border: 1px solid #E7ECF5;
    color: darkblue;
    font-family: "Roboto-Regular", sans-serif;
    height: 30px;
  }

  &.prepend {
    .input-group-prepend {
      .input-group-text {
        border-radius: 10px 0 0 10px;
        padding-left: 12px;
        padding-right: 1px;
      }
    }

    .form-control {
      border-left: 0 !important;
      border-radius: 0 10px 10px 0;
    }
  }

  &.append {
    .input-group-append {
      .input-group-text {
        border-radius: 0 10px 10px 0;
        padding-left: 0;
      }
    }

    .form-control {
      border-radius: 10px 0 0 10px;
      border-right: 0 !important;
    }
  }

  &.prepend.append {
    .form-control {
      border-radius: 0;
    }
  }
}


