@mixin min-width-576 {
  @media (min-width: 576px) {
    @content;
  }
}

@mixin min-width-768 {
  @media (min-width: 768px) {
    @content;
  }
}

@mixin min-width-992 {
  @media (min-width: 992px) {
    @content;
  }
}

@mixin min-width-1200 {
  @media (min-width: 1200px) {
    @content;
  }
}

@mixin min-width-1400 {
  @media (min-width: 1400px) {
    @content;
  }
}

@mixin for-min-width($size) {
  @media (min-width: $size) {
    @content;
  }
}

@mixin for-max-width($size) {
  @media (max-width: $size) {
    @content;
  }
}

// media query for IE10+
@mixin for-ie {
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    @content;
  }
}
