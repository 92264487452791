/* You can add global styles to this file, and also import other style files */
@import "@ng-select/ng-select/themes/default.theme.css";
@import "bootstrap/dist/css/bootstrap.css";

@import "assets/scss/buttons";
@import "assets/fonts/index.css";
@import "assets/scss/ng-select";
@import "assets/scss/modals";
@import "assets/scss/fonts_sizes";
@import "assets/scss/checkbox-radio";
@import "assets/scss/variables";
@import "assets/scss/bootstrap";
@import "assets/scss/forms";
@import "assets/scss/breakpoints";
@import "assets/scss/utils";
@import "assets/scss/md-input";

body {
  font-family: 'Font-Regular', sans-serif;
  overflow-x: hidden;
}

html {
  scroll-behavior: smooth;
}

.loading {
  min-height: 50vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.modal-award-details {
  @include min-width-768 {
    max-width: 400px;
  }

  @include min-width-992 {
    max-width: 400px;
  }

  @include min-width-576 {
    max-width: 400px;
  }
}
