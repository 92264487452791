
::ng-deep {

  .form-check {
    align-items: center;
    display: flex;
    padding-left: 0;
    margin-right: .75rem;
  }

  .form-check-input[type="radio"]:checked {
    background-color: #7F0EFC;
    border-color: #7F0EFC;
  }

  //CHECKBOX
  .form-check input[type="checkbox"] {
    display: none;
  }

  .form-check-common input[type="checkbox"] + label span {
    background: url("~assets/images/svg/check.svg") left top no-repeat;
  }

  .form-check-square input[type="checkbox"] + label span {
    background: url("~assets/images/svg/check.svg") left top no-repeat;
  }

  .form-check input[type="checkbox"] + label span {
    display: inline-block;
    width: 25px;
    height: 25px;
    padding: 10px;
    margin: 0 2px 0 0;
    vertical-align: middle;
    cursor: pointer;
    text-align: left;
  }

  .form-check-common input[type="checkbox"]:checked + label span {
    background: url("~assets/images/svg/check-active.svg") center no-repeat;
  }

  .form-check-square input[type="checkbox"]:checked + label span {
    background: url("~assets/images/svg/check-active.svg") center no-repeat;
  }

  .form-check-common input[type="checkbox"]:checked {
    label {
      background: red !important;
    }
  }
}
