$primary: #626262;
$secondary: #FF00E5;
$tertiary: #7F0EFC;
$white: #ffffff;
$black: #000000;

$color_1: #A100B4;
$color_2: #828282;
$color_3: #ced6e6;
$color_4: #3E3E3E;
$color_5: #F3971B;
$color_6: #5A5A5A;
$color_7: #525252;
$color_8: #888888E6;


.color-white {
  color: $white;
}

.color-primary {
  color: $primary;
}

.color-secondary {
  color: $secondary;
}

.color-tertiary {
  color: $tertiary;
}

.color-2 {
  color: $color_2;
}

.color-4 {
  color: $color_4;
}

.color-5 {
  color: $color_5;
}

.color-6 {
  color: $color_6;
}

.color-7 {
  color: $color_7;
}

.color-8 {
  color: $color_8;
}
