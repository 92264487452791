@font-face {
  font-family: 'Font-Bold';
  src: url("Lato/Lato-Bold.ttf") format("opentype");
}

.font-bold {
  font-family: "Font-Bold", sans-serif;
}

@font-face {
  font-family: 'Font-Black';
  src: url("Lato/Lato-Black.ttf") format("opentype");
}

.font-black {
  font-family: "Font-Black", sans-serif;
}

@font-face {
  font-family: 'Font-Regular';
  src: url("Lato/Lato-Regular.ttf") format("truetype");
}

.font-regular {
  font-family: "Font-Regular", sans-serif;
}

@font-face {
  font-family: 'Font-light';
  src: url("Lato/Lato-Light.ttf") format("opentype");
}

.font-light {
  font-family: "Font-light", sans-serif;
}
