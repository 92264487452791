.modal {
  align-items: center;
  display: flex !important;
  justify-content: center;

  .modal-content {
    border-radius: 10px;
  }

  .modal-header {
    border-bottom: 0;
    padding: 0.5rem 1rem;
  }
}
