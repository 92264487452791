$ft-8: 0.5rem;
$ft-9: 0.5625rem;
$ft-10: 0.625rem;
$ft-11: 0.6875rem;
$ft-12: 0.75rem;
$ft-13: 0.8125rem;
$ft-14: 0.875rem;
$ft-15: 0.9375rem;
$ft-16: 1rem;
$ft-17: 1.0625rem;
$ft-18: 1.125rem;
$ft-19: 1.1875rem;
$ft-20: 1.25rem;
$ft-21: 1.3125rem;
$ft-22: 1.375rem;
$ft-23: 1.4375rem;
$ft-24: 1.5rem;
$ft-25: 1.5625rem;
$ft-26: 1.625rem;
$ft-27: 1.6875rem;
$ft-28: 1.75rem;
$ft-29: 1.8125rem;
$ft-30: 1.875rem;
$ft-35: 2.1875rem;
$ft-40: 2.5rem;
$ft-45: 2.8125rem;
$ft-50: 3.125rem;
$ft-75: 4.6875rem;

.ft-8 {
  font-size: $ft-8;
}

.ft-9 {
  font-size: $ft-9;
}

.ft-10 {
  font-size: $ft-10;
}

.ft-11 {
  font-size: $ft-11;
}

.ft-12 {
  font-size: $ft-12;
}

.ft-13 {
  font-size: $ft-13;
}

.ft-14 {
  font-size: $ft-14;
}

.ft-15 {
  font-size: $ft-15;
}

.ft-16 {
  font-size: $ft-16;
}

.ft-17 {
  font-size: $ft-17;
}

.ft-18 {
  font-size: $ft-18;
}

.ft-19 {
  font-size: $ft-19;
}

.ft-20 {
  font-size: $ft-20;
}

.ft-21 {
  font-size: $ft-21;
}

.ft-22 {
  font-size: $ft-22;
}

.ft-23 {
  font-size: $ft-23;
}

.ft-24 {
  font-size: $ft-24;
}

.ft-25 {
  font-size: $ft-25;
}

.ft-26 {
  font-size: $ft-26;
}

.ft-27 {
  font-size: $ft-27;
}

.ft-28 {
  font-size: $ft-28;
}

.ft-29 {
  font-size: $ft-29;
}

.ft-30 {
  font-size: $ft-30;
}

.ft-35 {
  font-size: $ft-35;
}

.ft-40 {
  font-size: $ft-40;
}

.ft-45 {
  font-size: $ft-45;
}

.ft-50 {
  font-size: $ft-50;
}

.ft-75 {
  font-size: $ft-75;
}
