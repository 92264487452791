@import "assets/scss/variables";

.ng-select {

  &.contact-select {
    &.ng-select-focused:not(.ng-select-opened) > .ng-select-container {
      box-shadow: none !important;
    }

    .ng-select-container {
      height: 77px;
      box-shadow: 0 4px 4px 0 #00000040;
      border-radius: 13px;
      border: none;
      padding: 0 16px;
    }
  }
}

.ng-select .ng-arrow-wrapper .ng-arrow {
  border-color: #A9ADE0 transparent transparent;
}
