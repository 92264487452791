@import "assets/scss/variables";
//@import "~assets/scss/breakpoints";

input {
  &:focus {
    outline: none !important;
    box-shadow: none !important;
  }
}

.md-input {
  position: relative;

  input {
    color: $black;
    font-size: 15px !important;
    padding: 25px 10px 10px 15px;
    display: block;
    border: 1px solid #e7ecf5;
    height: 52px;
    font-family: 'robotomedium', sans-serif;

    &:focus {
      outline: none;
      padding-top: 25px;
    }

    &::placeholder {
      opacity: 0;
    }

  }

  input:not(:placeholder-shown) + label,
  input:focus + label {
    top: 6px;
    left: 5px;
    padding: 0 12px;
    font-size: 15px;
    font-family: 'robotobold', sans-serif;
    font-weight: bold;
  }

  label {
    color: $black;
    font-family: 'robotoregular', sans-serif;
    opacity: 1;
    font-size: 15px;
    position: absolute;
    pointer-events: none;
    left: 15px;
    top: 15px;
    transition: 0.2s ease all;
    -moz-transition: 0.2s ease all;
    -webkit-transition: 0.2s ease all;
    z-index: 9;
  }
}

.form-group {
  .form-label {
    font-size: 12px;
    margin-bottom: 0;
  }

  .form-control {
    width: 100%;
    height: 30px;
    background: transparent;
    border-radius: 0;
    border: none;
    border-bottom: 1px solid black;
    font-size: 16px;
  }
}


