.btn-awards {
  background-color: white;
  padding: 10px 15px;
  color: #FF00E5;
  border-radius: 44px;
  font-family: "Font-Bold", sans-serif;

  img {
    width: 36px;
  }
}

.btn-login {
  background: linear-gradient(180deg, rgba(255, 0, 230, 0.901042) 0%, #770DFD 100%);
  box-shadow: 0 4px 4px 0 #00000040;
  height: 70px;
  color: white !important;
  width: 100%;
  font-size: 20px;
  border: none !important;
}
